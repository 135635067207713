import React from 'react';
import "./NavigationBar.css";

import IconSmall from '../assets/icon-small.png';

export default function NavigationBar() {
  return (
    <div class="navigation-bar">
      <div class="branding">
        <img src={IconSmall} alt="Summit Range LLC Logo" />
        <div>
          <span class="brand-text">Summit Range LLC</span>
          <span class="branding-subtitle">Web Services</span>
        </div>
      </div>
    </div>
  )
}
