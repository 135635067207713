import './App.css';

// components
import NavigationBar from './components/NavigationBar';

function App() {
  return (
    <div className="App">
      <NavigationBar />
      <div class="main-container">
        <div class="container">
          <img src="https://static.wixstatic.com/media/b1d418_9c9d25725c5c4ec5af3e1d52af1a1491~mv2.gif" alt="Under Construction" height="150px" draggable="false" />
          <div>
            <h1>Under Construction</h1>
            <p>Please check back soon, we will be up and running before you know it.</p>
          </div>
        </div>
      </div>
      <div class="footer">
        <p>Copyright &copy; 2023 Summit Range LLC. All rights reserved.</p>
      </div>
    </div>
  );
}

export default App;
